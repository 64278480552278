import { Compiler, Component, HostListener, isDevMode } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BuildVersionService } from './shared/services/build-version.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Vendor Portal';
  devmode: boolean;
  has_ui_update: boolean = false
  is_localhost: boolean = false
  currentApplicationVersion = environment.appVersion;
  deviceInfo= {
    browser: '',
    browser_version:'',
    device: '',
    deviceType:'',
    orientation:'',
    os:'',
    os_version:'',
  };
  constructor(private _compiler: Compiler,private deviceService: DeviceDetectorService,  private version: BuildVersionService){}

  ngOnInit(): void {
    this.check();

    this.testBrowser();

    this.version.hasUpdate$.subscribe(val => {
      this.has_ui_update = val
    })

    if (window.location.hostname == 'localhost' || window.location.hostname == '127.0.0.1') {
      this.is_localhost = true
    }
  }



  check() {
    if (isDevMode()) {
      this.devmode = true;
    } else {
      this.devmode = false;
    }
  }

   testBrowser() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    // console.log("deviceInfo",this.deviceInfo);
    // console.log("browser_version",this.deviceInfo.browser_version);
    // console.log("browser_version",Number(parseFloat(this.deviceInfo.browser_version)));
    // console.log(isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    // console.log(isTablet);  // returns if the device us a tablet (iPad etc)
    // console.log(isDesktopDevice); // returns if the app is running on a Desktop browser.

    if(this.deviceInfo.browser === 'Chrome' && Number(parseFloat(this.deviceInfo.browser_version)) < 80){
      alert(`${this.deviceInfo.browser} Version ${Number(parseFloat(this.deviceInfo.browser_version))} is not currently suported.\nPlease use the latest version of Chrome, Opera, Firefox, Safari or Edge.`);

    }else if(this.deviceInfo.browser === 'MS-Edge-Chromium' && Number(parseFloat(this.deviceInfo.browser_version) < 80)){
      alert(`${this.deviceInfo.browser} Version ${Number(parseFloat(this.deviceInfo.browser_version))} is not currently suported.\nPlease use the latest version of Chrome, Opera, Firefox, Safari or Edge.`);

    }else if(this.deviceInfo.browser === 'Firefox' && Number(parseFloat(this.deviceInfo.browser_version) < 74)){
      alert(`${this.deviceInfo.browser} Version ${Number(parseFloat(this.deviceInfo.browser_version))} is not currently suported.\nPlease use the latest version of Chrome, Opera, Firefox, Safari or Edge.`);

    }else if(this.deviceInfo.browser === 'Safari' && Number(parseFloat(this.deviceInfo.browser_version) < 13)){
      alert(`${this.deviceInfo.browser} Version ${Number(parseFloat(this.deviceInfo.browser_version))} is not currently suported.\nPlease use the latest version of Chrome, Opera, Firefox, Safari or Edge.`);
    }else if(this.deviceInfo.browser === 'Opera' && Number(parseFloat(this.deviceInfo.browser_version) < 67)){
      alert(`${this.deviceInfo.browser} Version ${Number(parseFloat(this.deviceInfo.browser_version))} is not currently suported.\nPlease use the latest version of Chrome, Opera, Firefox, Safari or Edge.`);

    }else if(this.deviceInfo.browser != 'Chrome' && this.deviceInfo.browser != 'MS-Edge-Chromium' && this.deviceInfo.browser != 'Firefox' && this.deviceInfo.browser != 'Safari'){
      alert(`${this.deviceInfo.browser} Version ${Number(parseFloat(this.deviceInfo.browser_version))} is not currently suported.\nPlease use the latest version of Chrome, Opera, Firefox, Safari or Edge.`);
    }
   }

   hardReload() {
    this.version.removeCacheReload(window.location.origin)
  }


}
