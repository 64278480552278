<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
<notifier-container></notifier-container>

<router-outlet></router-outlet>

<div *ngIf="devmode" class="dev-prod-mode-sticker">Dev mode</div>

<div *ngIf="has_ui_update && !is_localhost" class="has-ui-update">
  <div class="d-flex align-items-center">
      <div class="mr-2">
          <i class="fa fa-sync mr-2"></i>
          <span class="update-title">UI Update Available!</span>
          <br/>
          <small>If button not working press <button class="btn btn-sm btn-secondary reload-btn">CTRL + F5</button> to hard reload.</small>
      </div>

      <button class="btn btn-primary btn-sm update-btn" (click)="hardReload()">Update</button>
  </div>
</div>

